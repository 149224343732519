export type OrderTransaction = {
    id: string
    orderId: string
    platformOrderId?: string
    created: string
    amount: number
    transactionType: TransactionType
    transactionStatus: TransactionStatus
    transactionReference: string
    transactionReason?: string
    errorMessage?: string
    errorDetails?: string
    swPaymentErrorCategory?: string
    paymentCallbackDetails?: string
    notes?: string
}

export enum TransactionType {
    Auth = "Auth",
    Capture = "Capture",
    Refund = "Refund",
    Void = "Void",
    Purchase = "Purchase",
}

export enum TransactionStatus {
    Pending = "Pending",
    Success = "Success",
    Failure = "Failure",
    Cancelled = "Cancelled",
}

export const allTransactionTypes: TransactionStatus[] = [
    TransactionStatus.Pending,
    TransactionStatus.Cancelled,
    TransactionStatus.Failure,
    TransactionStatus.Success,
]

export type CaptureTransactionResult = {
    status: TransactionStatus
    success: boolean
    wasAlreadyCaptured: boolean
}

export type RefundTransactionResult = {
    status: TransactionStatus
    success: boolean
    requiresRedirect: boolean
    redirectUrl: string
}
