export const allPlatforms = ["woocommerce", "magento", "magento2", "prestashop"] as const
export type TPlatformValue = (typeof allPlatforms)[number]

export interface WebshopDetails {
    webshopId: string
    hostName: string
    clientId: string
    mcc: string
    platform: TPlatformValue
}
